import { invariant } from "@epic-web/invariant";
import type { ActionFunction, LoaderFunction } from "@remix-run/node";
import {
	useActionData,
	useFetcher,
	useLoaderData,
	useRouteLoaderData,
} from "@remix-run/react";

export function useTurboStreamLoaderData<T extends LoaderFunction>() {
	return useLoaderData() as unknown as Awaited<ReturnType<T>>;
}

export function useTurboStreamActionData<T extends ActionFunction>() {
	return useActionData() as unknown as Awaited<ReturnType<T>> | undefined;
}

export function useTurboStreamRouteLoaderData<T extends LoaderFunction>(
	id: string,
	nullable = false,
) {
	const data = useRouteLoaderData(id) as unknown as Awaited<ReturnType<T>>;

	if (nullable) return data;
	invariant(data, `useTurboStreamRouteLoaderData should be used under ${id}`);
	return data;
}
export function useTurboStreamFetcher<
	T extends LoaderFunction | ActionFunction,
>() {
	return useFetcher<T>();
}
